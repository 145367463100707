<template>
  <div id="medical-insurances" class="medical-insurances">
    <div class="text-between">
      <div class="insurance-title">
        <p>Convênios</p>
      </div>
      <b-button
        v-can="'CcConv2'"
        variant="primary"
        @click="createHealthPlan()"
      >
        Novo convênio
      </b-button>
    </div>

    <div class="particular-config-wrapper">
      <div class="description mb-2">Selecione abaixo o(s) plano(s) que deseja vincular a todos os pacientes automaticamente:</div>      
      <div class="row">
        <div class="col-2">
          <div class="checkbox-wrapper">
            <Check
              :value="clinic.particular_auto"
              @input="updateClinicParticularAuto"
            />
            <div>Particular automático</div>
          </div>
        </div>
        <div class="col-3">
          <div id="help-icon" class="checkbox-wrapper">
            <b-tooltip target="help-icon" placement="bottom" v-if="!showInvoicingSus() && statusRequestFatSus === 'em aberto'">
              Pedido solicitado
            </b-tooltip>
            <Check
              :value="clinic.sus_auto"
              :disabled="!showInvoicingSus()"
              @input="updateClinicSusAuto"
            />
            <div @click="susBillingContractingModal()">
              SUS automático
              <b-badge variant="light" v-if="!showInvoicingSus()" class="link description">(Se houver interesse, clique aqui para ativar o Faturamento SUS)</b-badge>
            </div>            
          </div>
        </div>
      </div>      
    </div>

    <!-- Modal Contratação SUS -->
    <b-modal 
      v-model="isModalInvoicingSus" 
      hide-header 
      hide-footer 
      centered
      content-class="custom-fade"
      :dialog-class="'mt-10'">
        <div class="row my-3">
          <div class="col">
            <img src="@/assets/images/online-doctor.png" class="online-doctor center-block" height="250px">
          </div>
        </div>
        <div class="row my-3">
          <div class="col">
            <p class="text-center">
              Clique abaixo em "Contratar" para ativar o Faturamento SUS
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col text-center">
            <b-button variant="primary" @click="submitCommercialFeatureRequest">Contratar</b-button>
          </div>
        </div>
    </b-modal>

    <!-- Modal Agradecimento SUS -->
    <b-modal 
      v-model="isModelConfirmation" 
      hide-footer 
      centered
      content-class="custom-fade"
      title="Contratar">
      <div class="row my-3">
          <div class="col">
            <p class="text-center">
              <strong>Obrigado por escolher o Faturamento SUS!</strong>
            </p>
          </div>
        </div>
        <div class="row my-3">
          <div class="col">
            <p class="text-center">
              Nosso time comercial entrará em contato em breve para fornecer mais informações e dar sequência no processo de aquisição dessa funcionalidade.
            </p>
          </div>
        </div>
    </b-modal>

    <div class="row">
      <div class="col-9">
        <b-form-input
          class="search-input"
          autocomplete="off"
          placeholder="Buscar convênio por nome ou código ANS..."
          v-model="query"
          debounce="500"
        />
      </div>
      <div class="col-3">
        <multiselect
          class="with-border"
          placeholder="Todos os tipos de faturamento"
          v-model="InvoicingOption"
          :options="InvoicingOptions"
          :option-height="40"
          :showLabels="false"
          :searchable="true"
          :allowEmpty="false"
          label="label"
          track-by="value"
        />
      </div>
    </div>

    <b-table-simple striped>
      <tbody>
        <tr>
          <th>Nome do convênio</th>
          <th>Código / Registro</th>
          <th>Retorno</th>
          <th>Faturamento</th>
          <th width="24"></th>
        </tr>
        <tr v-for="(clinicHealthPlan, index) of clinicHealthPlans" :key="index">
          <td class="p-3">
            {{
              clinicHealthPlan.health_plan.fantasy_name !== null &&
              clinicHealthPlan.health_plan.fantasy_name !== ''
                ? clinicHealthPlan.health_plan.fantasy_name
                : clinicHealthPlan.health_plan.company_name
            }}
          </td>
          <td class="p-3">{{ clinicHealthPlan.health_plan.ans_code }}</td>
          <td class="p-3">{{ clinicHealthPlan?.return }} dias</td>
          <td class="p-3">{{ clinicHealthPlan?.invoicing_tiss_active === 1 ? 'TISS' : clinicHealthPlan?.invoicing_sus_active === 1 ? 'SUS' : '-' }}</td>
          <td class="p-3">
            <div>
              <div class="actions">
                <Pencil
                  v-can="'CcConv3'"
                  v-b-tooltip.hover
                  class="icon edit"
                  title="Editar"
                  @click="editHealthPlan(clinicHealthPlan)"
                />
                <Trash
                  v-can="'CcConv4'"
                  v-b-tooltip.hover
                  title="Excluir"
                  class="icon trash"
                  @click="removeHealthPlan(clinicHealthPlan)"
                  v-if="!clinicHealthPlan.health_plan.system_health_plan" 
                />
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <div class="pagination">
    <b-pagination
      v-model="page"
      :total-rows="count"
      :per-page="limit"
      first-number
      last-number
      size="sm"
    />
    </div>
  </div>
</template>

<script>
import { userHasPermission, getCurrentClinic, setCurrentClinic, getCurrentUser} from '@/utils/localStorageManager'
import { showInvoicingSus } from '@/utils/permissionsHelp'

export default {
  name: 'MedicalInsurancesView',
  components: {
    Check: () => import('@/components/General/Check'),
    Pencil: () => import('@/assets/icons/edit.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
  },
  created() {
    this.loadHealthPlans();
    this.getRequestFaturamentoSus();
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      clinicHealthPlan: null,
      clinicHealthPlans: [],
      query: '',
      page: 1,
      count: 0,
      limit: 0,
      InvoicingOption: null,
      InvoicingOptions: [
        { label: "Todos os tipos de faturamento", value: null},
        { label: "SUS", value: 'sus'},
        {	label: "TISS", value: 'tiss'},
				{ label: "-", value: '-'}
			],
      isModalInvoicingSus: false,
      isModelConfirmation: false,
      statusRequestFatSus: null
    }
  },
  methods: {
    userHasPermission,
    async loadHealthPlans() {
      const isLoading = this.$loading.show()
      try {
        const res = await this.api.getClinicHealthPlans(this.clinic.id, this.page, this.query, this.InvoicingOption?.value)
        this.clinicHealthPlans = res.data.data;
        this.count = res.data.total;
        this.limit = res.data.per_page;
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateClinicParticularAuto(value) {
      const loading = this.$loading.show()
      try {
        await this.api.updateClinicData(this.clinic.id, { particular_auto: value })
        this.clinic.particular_auto = value
        setCurrentClinic(this.clinic)
        this.$toast.success('Atualizado com sucesso')
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async updateClinicSusAuto(value) {
      const loading = this.$loading.show()
      try {
        await this.api.updateClinicData(this.clinic.id, { sus_auto: value })
        this.clinic.sus_auto = value
        setCurrentClinic(this.clinic)
        this.$toast.success('Atualizado com sucesso')
      } catch(err) {
        this.$toast.error(err.message)
      } finally {
        loading.hide()
      }
    },
    async removeHealthPlan(clinicHealthPlan) {
      const { id, health_plan_fantasy_name: name } = clinicHealthPlan

      const { isConfirmed } = await this.$swal.fire({
        icon: 'question',
        html: `<div class="body-alert">Tem certeza que deseja excluir o convênio<br />
            <span class="mt-1">${name}</span>?<br /><br />
            Esta ação não poderá ser desfeita.
          </div>`,
        showCancelButton: true,
        confirmButtonColor: '#F63220',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      })

      if (isConfirmed) {
        const isLoading = this.$loading.show()
        try {
          await this.api.deleteHealthPlanRequiredFields(id)
          await this.api.destroyClinicHealthPlans(id)
          this.$toast.success('Convênio removido com sucesso')
          await this.loadHealthPlans()
        } catch (err) {
          this.$toast.error(err.message)
        } finally {
          isLoading.hide()
        }
      }
    },
    createHealthPlan() {
      this.clinicHealthPlan = null
      this.$router.push('/convenio/')
    },
    editHealthPlan(clinicHealthPlan) {
      this.clinicHealthPlan = { ...clinicHealthPlan };
      this.$router.push(`/convenio/${this.clinicHealthPlan.id}/dados-gerais`)
    },
    updateClinicHealthPlan(clinicHealthPlan) {
      this.clinicHealthPlan = {
        ...this.clinicHealthPlan,
        ...clinicHealthPlan
      }
    },
    showModalInvoicingSus() {
      this.isModalInvoicingSus = true;
    },
    async getRequestFaturamentoSus() {
      const data = {
        'clinic_id': this.clinic.id,
        'feature': 'faturamento-sus'
      };

      await this.api.getFeatureRequestContactStatus(data)
      .then(res => {
        this.statusRequestFatSus = res.data.status
      })
      .catch(err => this.$toast.error(err.message));      
    },
    submitCommercialFeatureRequest() {
      const data = {
        'clinic_id': this.clinic.id,
        'person_id': this.user.id,
        'feature': 'faturamento-sus'
      };

      this.api.storeFeatureRequestContact(data)
      .then(res => {
        this.isModalInvoicingSus = false;
        this.isModelConfirmation = true;
        this.statusRequestFatSus = res.data.status;
      })
      .catch(err => this.$toast.error(err.message));      
    },
    showInvoicingSus,
    susBillingContractingModal() {
      if(!this.showInvoicingSus()){
        this.isModalInvoicingSus = (this.statusRequestFatSus !== 'em aberto' && this.statusRequestFatSus !== 'finalizado');
      }
    }
  },
  watch: {
    query() { this.loadHealthPlans(); },
    page() { this.loadHealthPlans(); },
    InvoicingOption() { this.loadHealthPlans(); },
  }
}
</script>

<style lang="scss" scoped>
#medical-insurances {
  .text-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 38px;
    align-items: center;
  }

  .insurance-title {
    p {
      font-family: 'Red Hat Display';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 134%;
      color: var(--dark-blue);
    }
  }

  .search-input {
    margin-bottom: 20px;
  }

  th {
    font-size: 14px;
    color: var(--type-active);
  }

  .td {
    vertical-align: center !important;
    padding: 0.75rem !important;
  }

  .pagination {
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .particular-config-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    border: 1px dashed var(--neutral-200);
    justify-content: center;
    border-radius: 8px;
    margin: 10px 0 20px 0;

    .description {
      color: var(--type-placeholder);
      margin-bottom: 0px;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    width: 100%;

    .icon {
      width: 25px;
      height: 25px;
      cursor: pointer;

      &.trash {
        fill: none;
        stroke: var(--states-red-soft);
      }

      &.edit {
        fill: none;
        stroke: var(--blue-500);
      }
    }

  }

  .new-medical-report {
    position: absolute;
    display: flex;
    justify-content: center;
    height: 38px;
    top: 0;
    right: 10px;
    z-index: 10;
  }

  .center-block{
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .custom-fade {
    transition: opacity 0.5s ease-in-out;
  }
}
</style>
